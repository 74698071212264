import React from "react"
import styled from "styled-components"

export function PDFViewer({ src }) {
  return <Document src={src}></Document>
}

const Document = styled.iframe`
  width: 100%;
  height: 100vh;
`

export function LinkCoverDocument({ children }) {
  return <PdfContainer>{children}</PdfContainer>
}

const PdfContainer = styled.div`
  padding: 2rem;
  margin: auto;
`
