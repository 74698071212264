import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export function ProductCard({ order, content, title, to, src }) {
  return (
    <Cover>
      <Wrapper to={to}>
        <Image src={src} order={order}></Image>
        <Content>
          <Title>{title}</Title>
          <Detail>{content}</Detail>
        </Content>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.div`
  margin: 2rem 0;
  border-radius: 1rem;
  border: ${props => props.theme.border.color};
  min-width: 35rem;
  max-width: 35rem;
  min-height: 35rem;
  padding: 1.5rem 0;
  background-color: ${props => props.theme.color.white};
  &:hover {
    /* border: 0.5px solid ${props => props.theme.color.highlight}; */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  }
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    max-width: 95%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const Wrapper = styled(Link)`
  padding: 1rem;
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const Image = styled.img`
  width: 25%;
  height: auto;
  order: 0;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 25%;
    order: 0;
  }
`

const Content = styled.div`
  width: 95%;
  text-align: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
  }
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.07em;
  color: #141414;
`

const Detail = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: ${props => props.theme.color.matteBlack}; ;
`
