import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

export function Button({ children, to }) {
  return <Cover to={to}>{children}</Cover>
}

const Cover = styled(Link)`
  background-color: ${props => props.theme.color.highlight};
  color: ${props => props.theme.color.white};
  padding: 1.5rem 2rem;
  font-weight: 600;
  font-size: ${props => props.theme.textsize.links};
  letter-spacing: 0.03em;
  border-radius: ${props => props.theme.border.buttonRadius};
  text-decoration: none;
  &:hover {
    opacity: 0.85;
  }
  @media only screen and (max-width: 75em) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 2.3rem;
  }
`

export function LightButton({ children, margin, to, borderColor }) {
  return (
    <LightCover borderColor={borderColor} to={to} margin={margin}>
      {children}
    </LightCover>
  )
}

const LightCover = styled(Link)`
  text-decoration: none;
  margin: ${props => props.margin};
  backdrop-filter: blur(0.4rem);
  color: ${props => props.borderColor || props.theme.color.white};
  padding: 1rem 2rem;
  font-weight: 600;
  font-size: ${props => props.theme.textsize.links};
  letter-spacing: 0.03em;
  border: 0.25rem solid ${props => props.borderColor || props.theme.color.white};
  border-radius: ${props => props.theme.border.buttonRadius};
  &:hover {
    opacity: 0.85;
    color: ${({ theme }) => theme.color.white};
  }
  @media only screen and (max-width: 75em) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 2.3rem;
  }
`
