import React from "react"
import { Mail, Phone } from "react-feather"
import styled from "styled-components"

export function CallAction() {
  return (
    <CallCover href="tel:1300100428">
      <CallIcon />
    </CallCover>
  )
}

const CallCover = styled.a`
  position: fixed;
  z-index: 7000;
  background-color: ${props => props.theme.color.highlight};
  padding: 1rem;
  border-radius: 50%;
  bottom: 1.5rem;
  right: 1.5rem;
`

const CallIcon = styled(Phone)`
  width: 3rem;
  height: 3rem;
  color: ${props => props.theme.color.white};
`

export function MailAction() {
  return (
    <MailCover href="mailto:info@icthub.com.au">
      <MailIcon />
    </MailCover>
  )
}

const MailCover = styled.a`
  position: fixed;
  z-index: 7000;
  background-color: ${props => props.theme.color.highlight};
  padding: 1rem;
  border-radius: 50%;
  bottom: 1.5rem;
  left: 1.5rem;
  &:active {
    color: ${({ theme }) => theme.color.dark};
  }
  &:hover {
    color: ${({ theme }) => theme.color.dark};
  }
`

const MailIcon = styled(Mail)`
  width: 3rem;
  height: 3rem;
  color: ${props => props.theme.color.white};
`
