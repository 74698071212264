import React from "react"
import styled from "styled-components"

export function DetailCard({ order, content, children, src, title }) {
  return (
    <Cover>
      <Wrapper>
        {src ? (
          <Icon order={order} src={src} alt={src} />
        ) : (
          <Image order={order}>{children}</Image>
        )}
        <Content>
          <Title>{title}</Title>
          <Detail>{content}</Detail>
        </Content>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.div`
  margin: 1.5rem 0;
  width: 100%;
  height: auto;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  @media only screen and (max-width: 75em) {
    width: 90%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 90%;
  }
  @media only screen and (max-width: 37.5em) {
    flex-direction: column;
    width: 95%;
  }
  @media only screen and (min-width: 112.5em) {
    width: 55%;
    justify-content: space-evenly;
  }
`
const Icon = styled.img`
  width: 15%;
  height: auto;
  order: 0;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 30%;
    order: 0;
  }
`

const Image = styled.div`
  width: 15%;
  height: auto;
  order: 0;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 30%;
    order: 0;
  }
`

const Content = styled.div`
  margin: 1.5rem 0;
  width: 75%;
  text-align: center;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 95%;
    text-align: center;
    margin: 1rem 0;
  }
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: ${props => props.theme.color.black};
`
const Detail = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: ${props => props.theme.color.black}; ;
`
