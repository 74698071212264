import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { X } from "react-feather"
export function LoginButton() {
  const [popUp, setPopUp] = useState(false)
  return (
    <>
      <Cover onClick={() => setPopUp(!popUp)}>
        <ButtonText>Resources</ButtonText>
      </Cover>
      {popUp ? (
        <Wrapper>
          <Grid>
            <Top>
              <Title>Resources</Title>
              <CloseIcon onClick={() => setPopUp(false)} />
            </Top>
            <Bottom>
              <Box href="https://myaccount.icthub.com.au/" target="_blank">
                <Heading>My Account</Heading>
              </Box>
              <Box href="http://icthub.servicedesk.atera.com/" target="_blank">
                <Heading>IT Support</Heading>
              </Box>
              {/* <Box>
                <Heading>LAN Assessment</Heading>
              </Box> */}
              {/* http://icthub.servicedesk.atera.com/ */}
            </Bottom>
          </Grid>
        </Wrapper>
      ) : null}
    </>
  )
}

const Cover = styled.div`
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.color.highlight};
  position: fixed;
  z-index: 2000;
  border-radius: 0.5rem;
  text-decoration: none;
  right: -4.7rem;
  bottom: 50%;
  transform: rotate(-90deg);
  cursor: pointer;
`

const ButtonText = styled.p`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: ${props => props.theme.color.white};
`

const Wrapper = styled.div`
  position: fixed;
  z-index: 222000;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  text-align: center;
`
const Grid = styled.div`
  box-shadow: 0 0 55px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  width: 50%;
  height: 100%;
  background-color: ${props => props.theme.color.white};
  border-radius: 1rem 1rem 0 0;
  @media only screen and (max-width: 75em) {
    width: 100%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
  }
`

const Top = styled.div`
  padding: 1.5rem 1.5rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const CloseIcon = styled(X)`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
`

const Title = styled.h3`
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.07em;
  color: #141414;
`
const Heading = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.07em;
  color: ${props => props.theme.color.black};
`

const Bottom = styled.div`
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* align-items: flex-start; */
`

const Box = styled(Link)`
  text-decoration: none;
  padding: 5rem 2.5rem;
  margin: 0.5rem;
  width: 100%;
  height: 33.33%;
  background-color: ${props => props.theme.color.highlight};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 75em) {
    width: 100%;
    height: 33.33%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 100%;
    height: 33.33%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: 33.33%;
    padding: 5rem 2.5rem;
  }
`
